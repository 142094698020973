<style lang="less" scoped>
.vehicle_from_input {
  width: 190px;
}
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="" name="explainer">
          <a-input v-model:value="searchForm.creator" placeholder="抄送人"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="onSearch">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    <a-table :columns="[sortColumn, ...columns]" :rowKey="(record, index) => { return index }" :data-source="listData"
      :pagination="pagination" size="small" :loading="loading" bordered @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <!-- <template #summary="{ record }">
        <div>
          <div v-for="(item, index) in summary" :key="index">
            <a-typography-text type="secondary">{{ item.label }}:{{ item.value }}</a-typography-text>
          </div>
        </div>
      </template> -->
      <template #operation="{ record }">
        <a @click="customRow(record)">详情</a>
      </template>
    </a-table>
  </suy-table>
  <a-modal v-model:visible="modalVisible" title="详情" width="80%" :maskClosable="false"
    :bodyStyle="{ height: '70vh', overflow: 'auto', background: '#f0f2f5', padding: '0 24px', }" destroyOnClose centered>
    <Detail v-model:flowInstanceId="flowInstanceId" type="" v-model:flowInstanceTaskId="flowInstanceTaskId"
      @ok="handleOk" />
  </a-modal>
</template>
<script>
import { toRefs, reactive, onMounted } from 'vue'
import { copyPage } from '@/api/system/bpm/instance'
import SuyTable from '@/components/SuyTable'
import Detail from './detail.vue'
export default {
  components: {
    SuyTable,
    Detail
  },
  setup () {
    const state = reactive({
      loading: false,
      modalVisible: false,
      orgList: [],
      flowInstanceId: '',
      flowInstanceTaskId: '',
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {},
      listData: [],
      sortColumn: { title: '序号', width: 50, slots: { customRender: 'sort' } },
      columns: [
        {
          title: '流程实例ID',
          dataIndex: 'flowInstanceId',
          align: 'center',
          key: 'flowInstanceId'
        },
        {
          title: '审批名称',
          dataIndex: 'instanceName',
          align: 'center',
          key: 'instanceName'
        },
        {
          title: '发起人',
          align: 'center',
          width:'5%',
          dataIndex: 'sponsor'
        },
        {
          title: '发起时间',
          align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '流程状态',
          align: 'center',
          dataIndex: 'flowInstanceStatus.label'
        },
        {
          title: '抄送人',
          dataIndex: 'creator',
          align: 'center',
          width:'5%',
          key: 'creator'
        },
        // {
        //   title: '摘要',
        //   dataIndex: 'summary',
        //   width:'15%',
        //   slots: {
        //     customRender: 'summary'
        //   }
        // },
        {
          title: '抄送内容',
          align: 'center',
          dataIndex: 'content',
          key: 'content'
        },
        {
          title: '抄送时间',
          align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          width:'5%',
          align: 'center',
          dataIndex: 'content',
          key: 'content',
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await copyPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.listData = records
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(loadData)
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const customRow = record => {
      state.flowInstanceId = record.flowInstanceId
      state.modalVisible = true
    }
    const handleOk = () => {
      state.modalVisible = false
      loadData()
    }
    return {
      ...toRefs(state),
      onSearch,
      loadData,
      handleTableChange,
      handleOk,
      customRow
    }
  }
}
</script>
